import { NgModule,  ErrorHandler, } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService, UserService, DeviceService, TicketService, DBGlobal, MapQuestService,
         ArchiveService, AlertService, MsgService } from './shared/services';
import {  AuthGuard, SignGuard } from './shared/guard';
import { HttpClientModule } from '@angular/common/http';
import {IMqttMessage, MqttModule, IMqttServiceOptions} from 'ngx-mqtt';
// import { AngularFileUploaderModule } from 'angular-file-uploader';
// import { NgxMaskModule, IConfig } from 'ngx-mask'
import { GlobalErrorHandler } from './app.error-handler';
import { PropertyService } from './shared/services/property.service';
// export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;
// Angular Data Grid Component
import { AgGridAngular } from 'ag-grid-angular';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: 'czbbabfy:czbbabfy:RvKTuMBqWnmM5ReG0xl6pLoNiRqT0pI8@tiny-silver-baboon.rmq.cloudamqp.com',
    port: 443,
    protocol: 'wss',
    path: '/ws/mqtt'
  };

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        FormsModule, ReactiveFormsModule,
        AppRoutingModule,
        AgGridAngular,
        HttpClientModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ],
    providers: [AuthGuard, AuthService, SignGuard, UserService, DeviceService, TicketService, PropertyService,
                DBGlobal, ArchiveService, AlertService, MsgService, MapQuestService, { provide: ErrorHandler, useClass: GlobalErrorHandler }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
