import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private router: Router) {}

  handleError(error: any): void {
    if (error.name === 'ChunkLoadError') {
      console.error('ChunkLoadError detected. Reloading the application...');
      window.location.reload();
    } else if (error.status) {
      console.error(`HTTP Error: ${error.status} - ${error.message}`);
      if (error.status === 401) {
        console.error('Unauthorized access. Redirecting to login...');
        window.location.href = '/login';
      } else if (error.status === 403) {
        console.error('Forbidden access.');
      } else if (error.status === 500) {
        console.error('Internal server error.');
      }
    } else if (error instanceof TypeError) {
      console.error('TypeError detected:', error.message);
    } else if (error instanceof ReferenceError) {
      console.error('ReferenceError detected:', error.message);
    } else if (error.ngNavigationError) {
      console.error('Navigation Error:', error.message);
      this.router.navigate(['/error']); // Redirect to a custom error page
    } else if (error.message && error.message.includes('Out of memory')) {
      console.error('Out of memory error detected. Consider optimizing your application.');
    } else {
      console.error('An unexpected error occurred:', error);
    }
  }
}